import './App.scss'
import React, { useState, useEffect } from 'react'

function App () {
  const [scrolling, setScrolling] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true)

      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleLinkClickToTerms = () => {
    window.open('https://aipodcast.topmusetech.com/user-agreement/terms_of_use.pdf', '_blank')
  }

  const handleLinkClickToPrivate = () => {
    window.open('https://aipodcast.topmusetech.com/user-agreement/privacy_notice.pdf', '_blank')
  }

  const handleLocateToAppStore = () => {
    window.open('https://apps.apple.com/us/app/fitmeal-health-fitness-recipe/id6504800448', '_blank')
  }

  return (
    <div className="App">
      <header className={scrolling ? "header header-scrolled" : "header"}>
        <div className='container'>
          <div className='navL'>
            <img src="./nav_logo.png" alt=""  style={{ width: '140px' }}/>
          </div>
          <div className='navR'>
            {/*<div*/}
            {/*  onClick={handleLocateToAppStore}*/}
            {/*  className='store'>App Store</div>*/}
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ backgroundColor: isHovered? '#469EF0' : '#469EF0'}}
              className='download'>
              Download
              {isHovered && (
                <div className='qrCode'>
                  <div className='triangle'>
                    <img src="./triangle.png" alt="" />
                  </div>
                  <div className='qrcode'>
                    <img src="./qrcode.png" alt="" />
                  </div>
                  <div className='text'>
                    <div>
                      <img src="./scan.png" alt="" />
                    </div>
                    <div>Scan to download</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <div className='section'>
        {/*<div className='decoration1'>*/}
        {/*  <img src="./decoration1.png" alt="" />*/}
        {/*</div>*/}
        {/*<div className='decoration2'>*/}
        {/*  <img src="./decoration2.png" alt="" />*/}
        {/*</div>*/}
        <div className='left'>
          <img src="./left.png" alt="" />
        </div>
        <div className='right'>
          <div className='icon'>
            <img src="./icon.png" alt="" />
          </div>
          <div className='name'>Muse</div>
          <div className='desc'>Make a Podcast about anything：Muse magically transforms your uploads into a 15-minute podcast, ensuring you learn more in less time. It can turn anything boring into something interesting and make the incomprehensible easy, from philosophy to novels—Muse does it all.</div>
        </div>
      </div>
      <footer className='footer'>
        <div className='container'>
          <div className='footerL'>
            <div className='title'>Muse</div>
            <div className='copyright'>2024 © Topmuse Pte Ltd. All Rights Reserved.</div>
            {/*<div className='poweredBy'>Powered by ChatGPT API and fleksy SDK</div>*/}
          </div>
          <div className='footerR'>
            <div className='item'>
              <div className='link'
                onClick={handleLinkClickToPrivate}
              >Privacy Policy</div>
            </div>
            <div className='item'>
              <div className='link'
                onClick={handleLinkClickToTerms}
              >Terms of Use</div>
            </div>
          </div>
        </div>

      </footer>
    </div>
  )
}

export default App
